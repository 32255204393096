<template>

<div>

  <attendance-items :attendanceSection="1" />

</div>

</template>

<script>
import AttendanceItems from '@/components/AttendanceItems';

export default {
  name: 'AttendanceList',
  components: {
    AttendanceItems,
  },
};
</script>

<style scoped>

</style>
